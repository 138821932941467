import * as React from "react";
import Head from "next/head";
import Image from "next/image";

// Import required MUI layout/components
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

import mainTheme from "./mainTheme";
import navStyles from "../styles/Navigation.module.css";

const LandingFooter = () => {
  return (
    <ThemeProvider theme={mainTheme}>
      <div style={mainTheme.custom.navigationBottom}>
        <Container maxWidth="lg">
          <div className={navStyles.footerLanderContainer}>
            <Grid container spacing={8}>
              <Grid item md={12} style={{ textAlign: "center" }}>
                <Link href="/">
                  <Image src="/headerLogo.svg" alt="Elm Street Logo" width={260} height={60} />
                </Link>
                <div style={{ marginTop: "24px" }}>©2025 Elm Street Technology</div>
              </Grid>
            </Grid>
            <div></div>
          </div>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default LandingFooter;
